*, *::before, *::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


@media screen and (max-width: 768px) {
  .page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding: 0;
    margin: 0;
    top: 0;
    padding-top: 50vh;
    overflow-y: scroll;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) {
  .page {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
}
  
.top-tags{
    bottom: auto;
    top: 35px;
}
  
.tags{
    color: rgb(208, 238, 218);
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    /* font-family: ''; */
}
  
  .bottom-tag-html{
    margin-left: -20px;
  }

  @media screen and (min-width: 768px) { 
      .container.contact-page,
      .container.about-page,
      .container.projects-page {
        .text-zone {
          
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 10%;
          top: 50%;
          transform: translateY(-50%);
          width: 35%;
          vertical-align: middle;
          max-height: 90%;
        
          h1 {
            display: flex;
            justify-content: center;
            font-size: 40px;
            font-family: 'Comfortaa';
            color: #0f9aeb;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: -5px;
          
            // &::before {
            //   content: '';
            //   position: absolute;
            //   width: 100%;
            //   margin-top: -10px;
            //   left: -10px;
            //   border: 2px  #8fe8ee59 solid;
            //   animation: fadeIn 2s backwards;
            // }
          
            &::after {
              content: '';
              position: absolute;
              width: calc(100% + 15px);
              margin-top: -10px;
              top: 77px;
              left: -10px;
              border-bottom: 4px  #8fe8ee59 solid;
            }
          }
        
          p {
            font-size: 13px;
            color:  #62d5f8e7;
          
            font-weight: 600;
            font-size: 1.3em;
            max-width: fit-content;

          
            text-align: justify;
          
            &:nth-of-type(1) {
              animation-delay: 1.1s;
              animation: pulse 1s;

            }
            &:nth-of-type(2) {
              animation-delay: 1.2s;
              animation: fadeIn 2s backwards;
            }
            &:nth-of-type(3) {
              animation-delay: 1.3s;
              animation: pulse 1s;
            }
          }
        }
      
        .text-animate-hover {
          &:hover {
            color: rgb(46, 200, 238);
          }
        }
      }
    }


    @media screen and (max-width: 768px) { 
     
      .container.contact-page,
      .container.projects-page {
        @media screen and (max-width: 390px) {
          .text-zone {
          top: 60%; 
          h1{
            font-size: 35px;
            }
          }
        }
        @media screen and (min-width: 391px) and (max-width: 768px) {
          .text-zone {
            top: 50%;
          h1{
            font-size: 47px;
          }
          }
        }
        .text-zone {
          padding-top: 20vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // position: absolute;
          transform: translateY(-50%);
          width: 100%;
          // overflow-y: scroll;
          // vertical-align: middle;
          max-height: 90%;
        
          h1 {
            display: flex;
            justify-content: center;
            font-family: 'Comfortaa';
            color: #0f9aeb;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: -5px;
          
          
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              margin-top: -10px;
              top: 77px;
              left: -10px;
              border-bottom: 4px  #8fe8ee59 solid;
            }
          }
        
          p {
            display: flex;
            width: 90%;
            font-size: 13px;
            color:  #62d5f8e7;
            font-weight: 600;
            font-size: 1.3em;
            max-width: fit-content;
          
            text-align: justify;
          
            &:nth-of-type(1) {
              animation-delay: 1.1s;
              animation: pulse 1s;

            }
            &:nth-of-type(2) {
              animation-delay: 1.2s;
              animation: fadeIn 2s backwards;
            }
            &:nth-of-type(3) {
              animation-delay: 1.3s;
              animation: pulse 1s;
            }
          }
        }
      
        .text-animate-hover {
          &:hover {
            color: rgb(46, 200, 238);
          }
        }
      }
      .container.about-page{
        overflow-y: scroll;
      
        @media screen and (max-width: 600px) {
          .text-zone {
          // top: 85%; 
          h1{
            font-size: 2.5em;
          }
          }
        }
        .text-zone {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // position: relative;
          // transform: translateY(-50%);
          width: 100%;
          overflow-y: scroll;
          // vertical-align: middle;
          // max-height: 90%;
        
        
          h1 {
            display: flex;
            justify-content: center;
            
            font-family: 'Comfortaa';
            color: #0f9aeb;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: -5px;
        
          
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              margin-top: -10px;
              top: 77px;
              left: -10px;
              border-bottom: 4px  #8fe8ee59 solid;
            }
          }
          
          p {
              font-size: 15px;
              padding: 10px;
              color:  #62d5f8e7;
              font-weight: 600;
              text-align: justify;
              text-justify: center;
            padding-right: 10px;
            
          
            &:nth-of-type(1) {
              animation-delay: 1.1s;
              animation: pulse 1s;
    
            }
            &:nth-of-type(2) {
              animation-delay: 1.2s;
              animation: fadeIn 2s backwards;
            }
            &:nth-of-type(3) {
              animation-delay: 1.3s;
              animation: pulse 1s;
            }
          }
        }
        .flex>p {
          font-size: 12px;
          color:  #62d5f8e7;
          padding: 0;
          font-weight: 600;
          text-align: justify;
          text-justify: center;
        }
      
        .text-animate-hover {
          &:hover {
            color: rgb(46, 200, 238);
          }
        }   
      }
      .container.projects-page>.text-zone{
        // top: 20% !important;
        padding-bottom: 30vh;
      }
    }

