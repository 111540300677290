

  @media screen and (min-width: 900px) { 
.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    opacity: 0;
    position: absolute;
    top: 10%;
    right: 20%;
    bottom: 0;
    left: auto;
    margin: auto;
    animation: fadeInRight 2s;
    animation-fill-mode: forwards;

      img{
        height: 500px;
        width: 500px;
        object-fit: contain;
        transform: rotate(25deg);
       
      }
  }
}


  @media screen and (max-width: 768px) { 
    @media screen and (max-width: 389px){
      .logo-container {
        top: 100%;
      }
    }
    @media screen and (min-width: 390px) and (max-width: 768px) {
      .logo-container {
        top: 65%;
      }
    }

    .logo-container {
      display: flex;
      align-self: center;
      z-index: 0;
      width: 400px;
      height: 400px;
      opacity: 0;
      position: absolute;
     
      left: 30%;
      bottom: 0;
      left: auto;
      margin: auto;
      animation: fadeInRight 2s;
      animation-fill-mode: forwards;
      z-index: -99;
      img{
        height: 80%;
        width: 80%;
        object-fit: contain;
        transform: rotate(25deg);
        overflow: hidden;
       
      }

}
}