
h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border:2px #28a4d9 solid;
  border-radius: 5px;
  animation: rollIn 2s;
  animation-fill-mode: forwards;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-weight: 600;
}

h3 {
  color: #19EC21;
  margin-top: 20px;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 2px;
}



@media screen and (min-width: 768px) { 
.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    border-radius: 15%;
  
    div {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
    
      border-radius: 15%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
            position: relative;
            border-radius: 15%;
        }
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #28a4d9;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #fafafa;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #dd0031;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #bf40df;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
}




@media screen and (max-width: 768px) { 
  .stage-cube-cont {
    display: flex;
    justify-content: center;
      width: 400px;
      height: 400px;
      top: 10%;
      padding-top: 18%;
      margin-left: 0;
      position: absolute;
      right: 20%;
      overflow: hidden;

    }
    
    .cubespinner {
      animation-name: spincube;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 12s;
      transform-style: preserve-3d;
      transform-origin: 100px 100px 0;
      // margin-left: calc(50% - 100px);
      border-radius: 15%;
    
      div {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 150px;
        height: 150px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
      
        border-radius: 15%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
          img {
              object-fit: contain;
              height: 150px;
              width: 150px;
              position: relative;
              border-radius: 15%;
          }
      }
    
      .face1 {
        transform: translateZ(100px);
        color: #28a4d9;
      }
      .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #fafafa;
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #dd0031;
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #bf40df;
      }
    }
    
    @keyframes spincube {
      from,
      to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      }
      16% {
        transform: rotateY(-90deg);
      }
      33% {
        transform: rotateY(-90deg) rotateZ(90deg);
      }
      50% {
        transform: rotateY(-180deg) rotateZ(90deg);
      }
      66% {
        transform: rotateY(-270deg) rotateX(90deg);
      }
      83% {
        transform: rotateX(90deg);
      }
    }
  }