h4{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    color: #19EC21;
    margin: 5px;
    animation: fadeInLeftBig 2s;
    animation-fill-mode: forwards;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-weight: 600;
  }

@media screen and (min-width: 768px) {
.home-page{

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-height: 90%;
        color:  #8fe8eec0;
        text-justify: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    h1 {
        // color: #fafafa;
        font-size: 47px;
        margin: 0;
        font-weight: 1000;

        &::before {
            content: '';
            color: #19EC21;
            font-size: 15px;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
            position: absolute;
        }
        &::after {
            content:'';
            color: #19EC21;
            font-size: 15px;
            margin-top: 15px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
            position: absolute;
        }
        img{
            width: 45px;
            height: 55px;
        }
        h4 {
            color: #19EC21;
            margin-top: 20px;
            font-weight: 400;
            font-size: 11px;
            letter-spacing: 2px;
        }
    }
}



.flat-button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #24a5e0;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #19EC21;
    margin-top: 25px;
    animation: all 1s 1.8s backwards;
    float: left;
    white-space: nowrap;
    // transition: 1s ease-in-out;
    &:hover {
        background-color: #19EC21;
        color: #fafafa
    }
}
}

@media screen and (max-width: 768px) {

    .home-page{
        @media screen and (max-width: 380px) {
            .text-zone {
            top: 50%; 
            h1{
              font-size: 30px;
            }
            }
          }
          @media screen and (min-width: 381px) and (max-width: 600px) {
            .text-zone {
            top: 40%; 
            h1{
              font-size: 47px;
            }
            }
          }

        .text-zone {
            position: absolute;
            left: 2%;
            transform: translateY(-50%);
            width: 50%;
            max-height: 90%;
            color:  #8fe8eec0;
            text-justify: auto;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
        h1 {
            // color: #fafafa;
            margin: 0;
            font-weight: 1000;
    
            &::before {
                content: '';
                color: #19EC21;
                font-size: 15px;
                margin-top: -40px;
                left: 15px;
                opacity: 0.6;
                position: absolute;
            }
            &::after {
                content:'';
                color: #19EC21;
                font-size: 15px;
                margin-top: 15px;
                margin-left: 20px;
                animation: fadeIn 1s 1.7s backwards;
                opacity: 0.6;
                position: absolute;
            }
            img{
                width: 45px;
                height: 55px;
            }
        }
    }
    
    h2 {
         color:  #62d5f8e7;
        margin-top: 20px;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }
    h4 {
        color:  #62d5f8e7;
       margin-top: 20px;
       font-weight: 400;
       font-size: 15px;
       letter-spacing: 3px;
       animation: fadeIn 1s 2s backwards;
   }
    
    .flat-button{
        color: #24a5e0;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #19EC21;
        margin-top: 25px;
        animation: all 1s 1.8s backwards;
        float: left;
        white-space: nowrap;
        // transition: 1s ease-in-out;
        &:hover {
            background-color: #19EC21;
            color: #fafafa
        }
    }

}


.justify {
    text-justify: center;
}