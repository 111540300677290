$primary-color : #0188f7;

@import 'animate.css';
@import '~loaders.css/src/animations/cube-transition.scss';

.loader-active{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin:auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    animation: fadeOut 1s 1s;
    animation-fill-mode: forwards;
}

// colors

// light blue #C1F6ED
// mid blue #3FD0C9
// blue #0f9aeb
// midlight green #2EAF7D
// mid green #449342
// dark green  #02353C