.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            justify-content: space-around;
            position: relative;
            padding: 0;
            margin: 0;
            width: 100%;
            list-style: none;
            margin: 2px 2px 0px 2px;
            margin-bottom: 10px;
            overflow: hidden;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
        }

    }
    input[type='text']{
        min-width: 49%;
        margin: 2px;
        border: 0;
        background:  #fafafa;
        height: 50px;
        font-size: 16px;
        color: #3FD0C9;
        padding: 0 20px;
        position: relative;
        float: left;
        box-sizing: border-box;
        box-shadow: rgba(11, 243, 208) 0px 2px 5px -1px, rgba(11, 243, 208, 0.3) 0px 1px 3px -1px;
    }
    input[type='email'] {
    width: 100%;
    margin: 2px;
    border: 0;
    background:  #fafafa;
    height: 50px;
    font-size: 16px;
    color: #8fe8ee;
    padding: 0 20px;
    position: relative;
    float: left;
    box-sizing: border-box;
    box-shadow: rgba(11, 243, 208) 0px 2px 5px -1px, rgba(11, 243, 208, 0.3) 0px 1px 3px -1px;
    }

    textarea {
        width: 100%;
        margin: 2px;
        border: 0;
        background:  #fafafa;
        color: #8fe8ee;
        box-shadow: rgba(11, 243, 208) 0px 2px 5px -1px, rgba(11, 243, 208, 0.3) 0px 1px 3px -1px;
        font-size: 16px;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        background: rgba(11, 243, 208, 0.925);
        color: #fafafa;
        font: 13px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 16px 25px;
        border: 2px solid #fafafa;
        float: left;
        border-radius: 4px;
        transition: all 0.5s ease-in-out;
    
        &:hover{
            background: #fafafa;
            color: rgba(11, 243, 208, 0.925);
            border: 2px solid rgba(11, 243, 208, 0.925);
            
            
        }

    }
}




@media screen and (min-width: 768px) {
.info-map{
    position: absolute;
    // background: #fafafa;
    background: rgba(40, 194, 199, 0.267);
    top: 10%;
    right: 45%;
    z-index: 99999;
    width: 267px;
    padding: 20px;
    border: 2px rgba(11, 243, 208, 0.925) solid;
    // color: #0f9aeb;
    color: #fafafa;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 17px;
    font-weight: 1000;
    opacity: 0;
    animation: fadeInDown 1s 1.5s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        color: #fafafa;
        // color: rgb(53, 204, 250);
    }
}

.map-wrap{
    background: rgba(11, 243, 208, 0.644);
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    width: 53%;
    height: 600px;
    padding: 20px;
    position: absolute;
    top: 23%;
    right: 0;
    opacity: 0;
    animation: fadeInDown 1s 1.5s;
    animation-fill-mode: forwards;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.leaflet-container{
    opacity: 0;
    width: 100%;
    height: 500px;
    position: relative;
    animation: backInRight 1.5s 1.2s;
    animation-fill-mode: forwards;
    z-index: 99999999;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
}



@media screen and (max-width: 768px) {
    .info-map{
        display: flex;
        flex-direction: column;
        position: relative;
        // background: #fafafa;
        background: rgba(40, 194, 199, 0.267);
        top: 100%;
        align-self: center;
        z-index: 99999;
        padding: 10px;
        width: auto;
        padding: 20px;
        border: 2px rgba(11, 243, 208, 0.925) solid;
        // color: #0f9aeb;
        color: #fafafa;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 17px;
        font-weight: 1000;
        opacity: 0;
        animation: fadeInDown 1s 1.5s;
        animation-fill-mode: forwards;
    
        span {
            font-size: 16px;
            display: block;
            color: #fafafa;
            // color: rgb(53, 204, 250);
        }
    }
    
    .map-wrap{
        background: rgba(11, 243, 208, 0.644);
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        height: 600px;
        padding: 20px;
        position: relative;
        top: 100%;
        right: 0;
        opacity: 0;
        animation: fadeInDown 1s 1.5s;
        animation-fill-mode: forwards;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    
    .leaflet-container{
        opacity: 0;
        width: 100%;
        height: 500px;
        position: relative;
        animation: backInRight 1.5s 1.2s;
        animation-fill-mode: forwards;
        z-index: 99999999;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

}

