@media screen and (min-width: 768px) { 
    .projects {
        display: flex;
        justify-content: space-evenly;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 40%;
        height: 100vh;
        top: 0;
        right: 0;
        // padding-top: 10%;
        // padding-right: 15%;
        margin-left: 0;
        position: absolute;
        
        overflow: hidden;    
 
    }
    .arrow-container, .arrow{
        display: none;
        visibility: none;
        opacity: 0;
    }
}



@media screen and (max-width: 768px) { 
    .projects {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        top: 80%;
        margin-left: 0;
        right: 0;
        position: relative;
        overflow: hidden;    
    }
    .arrow-container{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 20vh;
        top: 60%;
        left: 0;
        margin-left: 0;
        right: 0;
        position: absolute;
       
        :nth-child(1){
            animation: fadeOutDown 1.6s 1.6s infinite;
            animation-fill-mode: forwards;
            
        }
        :nth-child(2){
            animation: fadeOutDown 1.6s 1.8s infinite;
            animation-fill-mode: forwards;
            background-color: rgb(10, 228, 126);
        }
        :nth-child(3){
            animation: fadeOutDown 1.6s 2s infinite;
            animation-fill-mode: forwards;
            background-color: rgb(10, 228, 101);
        }
        .arrow{
            &::before,&::after{
            content: '';
            display: block;
            position: absolute;
            transform-origin: bottom right;
            background-color: rgb(10, 228, 181);
            width: 10px;
            height: 100px;
            border-radius: 10px;
            transform: translate(-50%, -50%) rotateZ(-45deg);
            }
            &::after {
                transform-origin: bottom left;
                transform: translate(-50%, -50%) rotateZ(45deg);
              }
          
        }
      
    
    }
}