@media screen and (min-width: 768px) {
  .nav-bar{
  background: #fafafa;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;
  box-shadow: rgba(40, 194, 199, 0.4) 5px 5px, rgba(41, 226, 124, 0.3) 10px 10px, rgba(40, 194, 199, 0.2) 15px 15px, rgba(41, 226, 124, 0.1) 20px 20px, rgba(40, 194, 199, 0.05) 25px 25px;
  
    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 40px;
            height: auto;
            background: transparent;

            &.sub-logo {
                width: 50px;
            }
        }
    } 
    .mobile-button, .mobile-button>span{
        display: none;
        visibility: none;
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;
        background: #3af334;
        
        a {
            font-size: 22px;
            background: rgb(41, 226, 124);
            color:#fafafa;
            display: block;
            line-height: 70px;
            height: 70px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }
            
            &:hover{
                color:#fafafa;

                svg{
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }
        
        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }
        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }
        a.project-link {
            &:after {
                content: 'PROJECTS';
            }
        }

        a.active {
            svg {
                color:#fafafa;
            }
        }
    }
    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin:0;

        li {
            a {
                padding: 7px 0;
                background: rgba(40, 194, 199);
                color:#fafafa;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover {
                    color: #ffd700;
                }
            }
        }
    }
    }
}


