*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html{
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-y: scroll
}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Lobster&family=Ubuntu:wght@300&display=swap');

input:focus, textarea:focus{
  outline: none;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Comfortaa', cursive;
  background: #fafafa;
  overflow: hidden;
  display: block;
  width: 100vw;
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media screen and (max-width: 600px) {
  
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font: 300 11px/1.4 'Ubuntu', 'sans-serif';
    color: #2EAF7D;
    background: #fafafa;
    overflow: hidden;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  span.text-zone{
    width: 100%;
  }
  
}

a:visited {
  text-decoration: none;
}


.flex{
  display: flex;
  width: 100%;
}
.space-even{
  justify-content: space-evenly;
}

.col{
  flex-direction: column;
}

.align{
  align-items: center;
}

.w100{
  width: 100%;
}
.w45{
  width: 45%;
}

.w50{
  width: 50%;
}

.padding-1{
  padding:1%;
}

