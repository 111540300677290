@media screen and (max-width: 768px) {
    .nav-bar{
    display: flex;
    flex-direction: row;
    background: #fafafa;
    width: 100%;
    height: 60px;
    position: absolute;
    margin: 0;
    padding: 0;
  
    top: 0;
    z-index: 3;
    box-shadow: rgba(40, 194, 199, 0.4) 5px 5px, rgba(41, 226, 124, 0.3) 10px 10px, rgba(40, 194, 199, 0.2) 15px 15px, rgba(41, 226, 124, 0.1) 20px 20px, rgba(40, 194, 199, 0.05) 25px 25px;
    
      .logo {
          display: flex;
          justify-content: center;
          height: 100%;
          padding: 0;
  
          img {
              display: block;
              margin: 8px auto;
              width: 40px;
              height: 50px;
              background: transparent;
  
              &.sub-logo {
                  width: 50px;
              }
          }
      } 
      .mobile-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          outline: none;
          border: 2px #34e6f3 solid;
          position: absolute;
          top: 0;
          right: 59%;
          height: 60px;
          width: 55px;
          background-color: #fafafa;
          z-index: 999999;
          .mobile-burger {
              content: '';
              display: block;
              position: relative;
              width: 80%;
              height: 2px;
              border-bottom: 2px #3af334 solid;
              animation: rollback 1s;
              animation-fill-mode: forwards;
            }    
      }

      .mobile-button.open>.mobile-burger:nth-child(1){
                position: absolute;
                align-self: center;
                animation: rotateCustom1 1s;
                animation-fill-mode: forwards;   
      }
      .mobile-button.open>.mobile-burger:nth-child(3) {
                position: absolute;
                align-self: center;
                animation: rotateCustom2 1s;
                animation-fill-mode: forwards;  
      }
      .mobile-button.open>.mobile-burger:nth-child(2){
          animation: rotateOut 1s;
          animation-fill-mode: forwards;
      }
      nav {
          display: flex;
          flex-direction: column;
          text-align: center;
          height: 60px;
          width: 60%;  
          padding-top: 60px;      
          animation: fadeInDown 1s;
          animation-fill-mode: forwards;
          
         
  
          background: #3af334;
          a {
              font-size: 22px;
              background: rgb(41, 226, 124);
              color:#fafafa;
              display: block;
              line-height: 60px;
              height: 60px;
              position: relative;
              text-decoration: none;
  
              i {
                  transition: all 0.3s ease-out;
              }
              
              &:hover{
                  color:#fafafa;
  
                  svg{
                      opacity: 0;
                  }
                  &:after {
                      opacity: 1;
                  }
              }
  
              &:after {
                  content: '';
                  font-size: 9px;
                  letter-spacing: 2px;
                  position: absolute;
                  bottom: 0;
                  display: block;
                  width: 100%;
                  text-align: center;
                  opacity: 0;
                  -webkit-transition: all 0.3s ease-out;
                  transition: all 0.3s ease-out;
              }
  
              &:first-child {
                  &::after {
                      content: 'HOME';
                  }
              }
          }
          
          a.about-link {
              &:after {
                  content: 'ABOUT';
              }
          }
          a.contact-link {
              &:after {
                  content: 'CONTACT';
              }
          }
          a.project-link {
              &:after {
                  content: 'PROJECTS';
              }
          }
  
          a.active {
              svg {
                  color:#fafafa;
              }
          }
      }
      ul {
          width: 50%;
          display: flex;
          justify-content: space-evenly;
          padding: 0;
          height: 60px;
          list-style: none;
          text-align: center;
          margin:0;
  
          li {
              display: flex;
              justify-content: center;
              width: 100%;
              height: 100%;
              a {
                  padding: 7px 0;
                  background: rgba(40, 194, 199);
                  color:#fafafa;
                  display: block;
                  font-size: 33px;  
                  width: 100%;
                  &:hover {
                      color: #ffd700;
                  }
              }
          }
      }
      }
  
  }
  
  .hide{
    position: relative;
    animation: fadeOutUp 1s !important;
    animation-fill-mode: forwards;
    opacity: 0;
    height: 20px !important;
    overflow: hidden;
  }

@keyframes rotateCustom1 {
    100% {transform:rotate(45deg); }
}

@keyframes rotateCustom1 {
    100% {transform:rotate(45deg); }
}

@keyframes rotateCustom2 {
    100% {transform:rotate(-45deg); }
}
@keyframes rotateBackCustom1 {
    100% {
        transform:rotate(-45deg);
        opacity: 1;
     }
}

@keyframes rotateBackCustom2 {
    100% {transform:rotate(45deg); }
}

@keyframes rollback {
    100% {transform:rotate(180deg); }
}