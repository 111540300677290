
@media screen and (min-width: 768px) { 
    .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    width: 70%;
    background: #8fe8ee;    
    padding: 1%;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    
    &:hover{
        width: 100%;
        height: 30vh;
        transition: height 0.7s ease-in-out;
        transition: width 0.7s ease-in-out;
    }
    &:hover > a, &:hover > p, &:hover > h1{
        z-index: 2;
    }
    img {
        position: absolute;
        top: 0;
        animation: fadeInDown 2s;
        animation-fill-mode: forwards;
        // object-fit: contain;
        object-fit: cover;
        height: 100%;
        z-index: 1;
        // height: 170px;
        width: 100%;
    }
    p{
        background-color: #fafafada;
        width: auto;
        text-align: center;
        padding: 1%;
        font-size: 1.3em;
        font-weight: 900;
    }
    h1 {
        display: flex;
        justify-content: center;
        margin: 2px;
        width: 100%;
        font-size: 18px;
        text-justify: auto;
        color: #fafafa;
        width: auto;
        padding: 10px;
        margin: 5px;
        background-color: rgba(0, 0, 0, 0.178);
        font-weight: 1000;
    }
    a {
        display: flex;
        text-decoration: none;
        width: 30%;
        font-size: 22px;
        padding: 10px;
        border-radius: 20px;
        justify-content: center;
        border: 2px #fafafa solid;
        background-color: black;
        color: #fafafa;
        animation: fadeIn 2s;
        animation-fill-mode: forwards;
        position: absolute;
        bottom: 5%;
        &:hover{
            background: #fafafa;
            border: 2px black solid;
            color: #8fe8ee;
            transition: all 0.3s ease-in-out;
        }
    }
    a:visited {
        text-decoration: none;

    }
    }
}




@media screen and (max-width: 768px) { 
    .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #8fe8ee;
    height: 250px;
    padding: 1%;
    margin-bottom: 25px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    img {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        height: 180px;
        width: 250px;
        animation: fadeInDown 2s;
        animation-fill-mode: forwards;
    }
    h1 {
        display: flex;
        justify-content: center;
        margin: 2px;
        width: 100%;
        font-size: 18px;
        text-justify: auto;
        color: #fafafa;
        font-weight: 1000;
    }
    a {
        display: flex;
        text-decoration: none;
        width: 60%;
        font-size: 16px;
        justify-content: center;
        border: 2px #fafafa solid;
        color: #fafafa;
        animation: fadeIn 2s;
        animation-fill-mode: forwards;
        &:hover{
            background: #fafafa;
            border: 2px #fafafa solid;
            color: #8fe8ee;
            transition: all 0.3s ease-in-out;
        }
    }
    a:visited {
        text-decoration: none;

    }
    }
}